import { QuestionLabel } from '@amo/core/components/containers';
import { DateField, Radio, Text } from '@amo/core/components/forms';
import {
    isValid,
    maxDateToday,
    onError,
    permisA,
    permisA1,
    permisA2,
    permisAM,
    permisB,
    required,
    requiredDateWithError,
} from '@amo/core/utils/validateField';
import { validateLicenseByVehicule } from '@amo/core/utils/validateLicense';
import moment from 'moment';
import { useEffect } from 'react';
import { Field } from 'redux-form';
import QuestionContentContainer from '../../../QuestionContent/QuestionContentContainer';

const GestionPermis = (props) => {
    const {
        vehicule,
        validDateNaissance,
        typePermisAM,
        typePermisA1,
        typePermisA2,
        typePermisA,
        typePermisB,
        permis,
        changeValue,
        unsetValue,
        dateNaissance,
        cylindreeVehicule,
        mineur,
        datePermisA,
        permisOnError,
        datePermisA2,
    } = props;
    let validLicense = validateLicenseByVehicule({ permis, vehicule, dateNaissance });
    useEffect(() => {
        if (typePermisAM || typePermisA1 || typePermisA2 || typePermisA || typePermisB) {
            // Si un permis est sélectionné, décocher "Je n'ai pas de permis"
            changeValue('noPermis', null); // ou toute autre valeur qui indique non sélectionné
        }
    }, [typePermisAM, typePermisA1, typePermisA2, typePermisA, typePermisB, changeValue]);
    return (
        <>
            {moment() > moment(validDateNaissance).add(14, 'years') && (
                <QuestionContentContainer name={'DemandeTarif[ListePersonnes][0][ListePermis]'}>
                    <Field
                        component={Text}
                        name={'permisOnError'}
                        type={'hidden'}
                        validate={onError}
                        style={{ display: 'none' }}
                    />

                    <QuestionLabel required>Quels permis possédez-vous&nbsp;?&nbsp;</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                            <div>
                                <Field
                                    component={Radio}
                                    name={'DemandeTarif[ListePersonnes][0][ListePermis][0][TypePermis]'}
                                    type={'checkbox'}
                                    label={'Permis AM (ou&nbsp;BSR)'}
                                    normalize={(v) => (v ? 'AM' : null)}
                                    format={(v) => v === 'AM'}
                                    onChange={() => {
                                        unsetValue('DemandeTarif[ListePersonnes][0][ListePermis][0][DatePermis]');
                                    }}
                                    className={'small'}
                                    dataCy={"permis-AM"}
                                />
                            </div>

                            {typePermisAM && (
                                <div className={'mb-5 blocPermis'}>
                                    <QuestionLabel className={'f-14 mt-0 mb-2'} required>
                                        Date d'obtention&nbsp;
                                    </QuestionLabel>

                                    <div className={'mt-3 mt-md-2'} data-cy={"date-permis-AM"}>
                                        <Field
                                            component={DateField}
                                            name={'DemandeTarif[ListePersonnes][0][ListePermis][0][DatePermis]'}
                                            label={'JJ/MM/AAAA'}
                                            validate={[
                                                requiredDateWithError,
                                                isValid,
                                                maxDateToday,
                                                permisAM,
                                                required,
                                            ]}
                                            maxDate={new Date()}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        {moment() > moment(validDateNaissance).add(16, 'years') && (
                            <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                <div>
                                    <Field
                                        component={Radio}
                                        name={'DemandeTarif[ListePersonnes][0][ListePermis][1][TypePermis]'}
                                        type={'checkbox'}
                                        label={'Permis A1'}
                                        normalize={(v) => (v ? 'A1' : null)}
                                        format={(v) => v === 'A1'}
                                        onChange={() => {
                                            unsetValue('DemandeTarif[ListePersonnes][0][ListePermis][1][DatePermis]');
                                        }}
                                        className={'small'}
                                        dataCy={"permis-A1"}
                                    />
                                </div>

                                {typePermisA1 && (
                                    <div className={'mb-5 blocPermis'}>
                                        <QuestionLabel className={'f-14 mt-0 mb-2'} required>
                                            Date d'obtention&nbsp;
                                        </QuestionLabel>

                                        <div className={'mt-3 mt-md-2'} data-cy={"date-permis-A1"}>
                                            <Field
                                                component={DateField}
                                                name={'DemandeTarif[ListePersonnes][0][ListePermis][1][DatePermis]'}
                                                label={'JJ/MM/AAAA'}
                                                validate={[
                                                    requiredDateWithError,
                                                    isValid,
                                                    maxDateToday,
                                                    permisA1,
                                                    required,
                                                ]}
                                                maxDate={new Date()}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {!mineur && (
                            <>
                                <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                    <div>
                                        <Field
                                            component={Radio}
                                            name={'DemandeTarif[ListePersonnes][0][ListePermis][2][TypePermis]'}
                                            type={'checkbox'}
                                            label={'Permis A2'}
                                            normalize={(v) => (v ? 'A2' : null)}
                                            format={(v) => v === 'A2'}
                                            onChange={() => {
                                                unsetValue(
                                                    'DemandeTarif[ListePersonnes][0][ListePermis][2][DatePermis]',
                                                );
                                            }}
                                            className={'small'}
                                            dataCy={"permis-A2"}
                                        />
                                    </div>

                                    {typePermisA2 && (
                                        <div className={'mb-5 blocPermis'}>
                                            <QuestionLabel className={'f-14 mt-0 mb-2'} required>
                                                Date d'obtention&nbsp;
                                            </QuestionLabel>

                                            <div className={'mt-3 mt-md-2'} data-cy={"date-permis-A2"}>
                                                <Field
                                                    component={DateField}
                                                    name={'DemandeTarif[ListePersonnes][0][ListePermis][2][DatePermis]'}
                                                    label={'JJ/MM/AAAA'}
                                                    validate={[
                                                        requiredDateWithError,
                                                        isValid,
                                                        maxDateToday,
                                                        permisA2,
                                                        required,
                                                    ]}
                                                    maxDate={new Date()}
                                                />
                                            </div>
                                            {!datePermisA &&
                                            moment.isMoment(datePermisA2) &&
                                            moment(datePermisA2).isValid() &&
                                            moment(datePermisA2) >= moment('2017-01-01') &&
                                            moment(datePermisA2) <= moment().subtract(2, 'years') ? (
                                                <div className={'bg-secondary-light p-4 rounded text-left mt-5'}>
                                                    <p className={'text-primary'}>
                                                        <small>
                                                            Avez vous passé la formation de 7h pour obtenir le permis A
                                                            ? Si oui, merci de renseigner le permis A.
                                                        </small>
                                                    </p>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    )}
                                </div>
                                {moment() > moment(validDateNaissance).add(16, 'years') && (
                                    <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                        <div>
                                            <Field
                                                component={Radio}
                                                name={'DemandeTarif[ListePersonnes][0][ListePermis][4][TypePermis]'}
                                                type={'checkbox'}
                                                label={'Permis B'}
                                                normalize={(v) => (v ? 'B' : null)}
                                                format={(v) => v === 'B'}
                                                onChange={() => {
                                                    unsetValue(
                                                        'DemandeTarif[ListePersonnes][0][ListePermis][4][DatePermis]',
                                                    );
                                                    changeValue('DemandeTarif[ListePersonnes][0][ValeurCrmAuto]', null);
                                                    changeValue(
                                                        'DemandeTarif[ListePersonnes][0][CrmAuto50Plus3Ans]',
                                                        null,
                                                    );
                                                }}
                                                className={'small'}
                                                dataCy={"permis-B"}
                                            />
                                        </div>
                                        {typePermisB && (
                                            <div className={'mb-5 blocPermis'}>
                                                <QuestionLabel className={'f-14 mt-0 mb-2'} required>
                                                    Date d'obtention&nbsp;
                                                </QuestionLabel>

                                                <div className={'mt-3 mt-md-2'} data-cy={"date-permis-B"}>
                                                    <Field
                                                        component={DateField}
                                                        name={
                                                            'DemandeTarif[ListePersonnes][0][ListePermis][4][DatePermis]'
                                                        }
                                                        label={'JJ/MM/AAAA'}
                                                        validate={[
                                                            requiredDateWithError,
                                                            isValid,
                                                            maxDateToday,
                                                            permisB,
                                                            required,
                                                        ]}
                                                        maxDate={new Date()}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                    <div>
                                        <Field
                                            component={Radio}
                                            name={'DemandeTarif[ListePersonnes][0][ListePermis][3][TypePermis]'}
                                            type={'checkbox'}
                                            label={'Permis A'}
                                            normalize={(v) => (v ? 'A' : null)}
                                            format={(v) => v === 'A'}
                                            onChange={() => {
                                                unsetValue(
                                                    'DemandeTarif[ListePersonnes][0][ListePermis][3][DatePermis]',
                                                );
                                            }}
                                            className={'small'}
                                            dataCy={"permis-A"}
                                        />
                                    </div>
                                    {typePermisA && (
                                        <div className={'mb-5 blocPermis'}>
                                            <QuestionLabel className={'f-14 mt-0 mb-2'} required>
                                                Date d'obtention&nbsp;
                                            </QuestionLabel>

                                            <div className={'mt-3 mt-md-2'} data-cy={"date-permis-A"}>
                                                <Field
                                                    component={DateField}
                                                    name={'DemandeTarif[ListePersonnes][0][ListePermis][3][DatePermis]'}
                                                    label={'JJ/MM/AAAA'}
                                                    validate={[
                                                        requiredDateWithError,
                                                        isValid,
                                                        maxDateToday,
                                                        permisA,
                                                        required,
                                                    ]}
                                                    maxDate={new Date()}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                        {cylindreeVehicule <= 50 && moment(validDateNaissance) < moment('1988-01-01') && (
                            <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                <div>
                                    <Field
                                        component={Radio}
                                        className={'small f-14'}
                                        name={'noPermis'}
                                        value={'0'}
                                        type={"checkbox"}
                                        label={'Je n\'ai pas de permis'}
                                        onClick={(e) => {
                                            const newValue = e.target.checked;
                                            if (newValue) {
                                                changeValue('DemandeTarif[ListePersonnes][0][ListePermis]', []);
                                                changeValue('noPermis', '0');
                                            } else {
                                                // Effectuez ici l'action correspondant à la décoche du bouton
                                                changeValue('noPermis', null);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {permisOnError && permisOnError === 'onError' && (
                        <div className={'bg-danger p-4 rounded text-left mt-5 f-14'}>
                            <p className={'mb-0'}>Vous devez renseigner au moins un permis.</p>
                        </div>
                    )}
                    {!(permisOnError && permisOnError === 'onError') && !validLicense?.status && (
                        <div className={'bg-danger p-4 rounded text-left mt-5 f-14'}>
                            <p className={'mb-0'}>
                                {validLicense?.message || 'Votre permis ne permet pas de conduire ce type de véhicule'}
                            </p>
                        </div>
                    )}
                    {validLicense?.status && validLicense?.message && (
                        <div className={'bg-warning p-4 rounded text-left mt-3 f-14'}>
                            <p className="mb-0">{validLicense?.message}</p>
                        </div>
                    )}
                </QuestionContentContainer>
            )}
        </>
    );
};

GestionPermis.defaultProps = {};
GestionPermis.propTypes = {};

export default GestionPermis;
